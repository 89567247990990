import CLINTRIALSXER_LOGO from 'assets/images/ClinTrialsXer.svg';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FiLogOut } from 'react-icons/fi';

import Typography from '../Typography';
import './navbar.scss';

type Props = {
    className?: string
}

// const appName = process.env.REACT_APP_SITE_TITLE;

export default function Navbar({ className = '' }: Props): JSX.Element {
    return (
        <div className={`navbar ${className}`} >
            {/* <Typography size={24}>{appName}</Typography> */}
            <Link to='/home'>
                <img
                    src={CLINTRIALSXER_LOGO}
                    height={20}
                    width={167}
                    alt='logo-temp' />
            </Link>
            <div className='navbar__right'>
                <Typography className='navbar__right--profile'>JD</Typography>
                <Dropdown>
                    <Dropdown.Toggle variant='success' id='dropdown-basic'>
                    Jane Doe
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item href='/login'><FiLogOut />Log out</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </div>
    );
}
