import PageLoadable from 'components/modules/Loadable/PageLoadable';

const AsyncNotFound = PageLoadable({ loader: () => import('./NotFound') });
const AsyncLogin = PageLoadable({ loader: () => import('./Login') });
const AsyncDashboard = PageLoadable({ loader: () => import('./Dashboard') });
const AsyncSignUp = PageLoadable({ loader: () => import('./SignUp') });
const AsyncForgotPassword = PageLoadable({ loader: () => import('./ForgotPassword') });
const AsyncOtp = PageLoadable({ loader: () => import('./Otp') });
// const AsyncSurveyIntro = PageLoadable({ loader: () => import('./SurveyIntro') });
// const AsyncSurvey = PageLoadable({ loader: () => import('./Survey') });
// const AsyncCompletedSurvey = PageLoadable({ loader: () => import('./CompletedSurvey') });
const AsyncSurveyResponses = PageLoadable({ loader: () => import('./SurveyResponses') });
const AsyncHome = PageLoadable({ loader: () => import('./Home') });
const AsyncPatientOverviewAnswers = PageLoadable({ loader: () => import('./PatientOverviewAnswers') });
// ROUTE IMPORT CODE GENERATOR INDICATOR DO NOT DELETE

const routes = {
    LOGIN: {
        component: AsyncLogin,
        path: '/login',
        authedRoute: false
    },
    DASHBOARD: {
        component: AsyncDashboard,
        path: '/dashboard',
        authedRoute: false
    },
    SIGN_UP: {
        component: AsyncSignUp,
        path: '/sign-up',
        authedRoute: false
    },
    FORGOT_PASSWORD: {
        component: AsyncForgotPassword,
        path: '/forgot-password',
        authedRoute: false
    },
    Otp: {
        component: AsyncOtp,
        path: '/otp',
        authedRoute: false
    },
    // SURVEY_INTRO: {
    //     component: AsyncSurveyIntro,
    //     path: '/survey/intro',
    //     authedRoute: false
    // },
    // COMPLETED_SURVEY: {
    //     component: AsyncCompletedSurvey,
    //     path: '/survey/completed',
    //     authedRoute: false
    // },
    SURVEY_RESPONSES: {
        component: AsyncSurveyResponses,
        path: '/survey/responses',
        authedRoute: true
    },
    // SURVEY: {
    //     component: AsyncSurvey,
    //     path: '/survey',
    //     authedRoute: false
    // },
    HOME: {
        component: AsyncHome,
        path: '/home',
        authedRoute: true
    },
    PATIENT_OVERVIEW_ANSWERS: {
        component: AsyncPatientOverviewAnswers,
        path: '/survey/patient/:id',
        authedRoute: true
    },
    // ROUTE ENTRY CODE GENERATOR INDICATOR DO NOT DELETE
    NOT_FOUND: {
        component: AsyncNotFound,
        path: '*',
        authedRoute: false
    }
};

export default routes;
